import '../bootstrap';

import React from 'react';
import styled, { css } from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box as LibBox, Flex } from '@glean/component-library/build/serverSide';

import { Box } from '../components/box';
import { Button } from '../components/button';
import { Layout } from '../components/layout';
import { MenuBar } from '../components/menu-bar/menu-bar';
import { Paragraph, SecondaryHeadline } from '../components/typography';
import { Item } from '../components/typography/typography';
import { useTheme } from '../styles';
import { getAppHost } from '../urlHelper';

const Content = styled.div`
    background-color: ${(props) => props.theme.colors.darkred};
    padding: 20px;
    position: relative;

    button {
        margin-top: 40px;
        display: block;
    }

    .box {
        max-width: 600px;
        margin: 40px auto;
        padding: 20px;
        border-radius: 20px;
        border-top-left-radius: 20px;
        position: relative;
    }

    .options {
        margin-top: 100px;

        b {
            font-size: 16px;
        }

        button {
            margin-top: 0;
            width: 100%;
        }
    }

    .lineover {
        text-decoration: line-through;
    }

    .iframe-container {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        height: 0;
    }
    .iframe-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @media (min-width: ${(props) => props.theme.screens.alt}px) {
        .box {
            max-width: 860px;
            margin: 20px;
            padding: 20px;
        }

        .flex {
            display: flex;
            justify-content: space-between;
        }

        .column {
            max-width: 300px;
        }

        .options {
            position: relative;
            max-width: 860px;
            margin: auto;
            display: flex;
            margin-top: 100px;

            & > * {
                flex: 1;
            }
        }

        .video {
            position: relative;
            max-width: 860px;
            margin: auto;
            margin-bottom: 60px;
        }
    }
`;

const Label = styled.div<{ alpha?: boolean }>`
    position: absolute;
    top: 30px;
    left: 0;
    height: 30px;
    width: 180px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: ${(props) => props.color};
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: ${(props) => props.theme.screens.alt}px) {
        ${(props) =>
            props.alpha &&
            css`
                right: 400px;
            `}
    }
`;

const animationProps = {
    initialOpacity: 1,
    initialY: 100,
};

const Options = () => {
    const { darkblue, white, green, pink } = useTheme().colors;
    const { sm, lg } = useTheme().fontSize;
    const plus = <FontAwesomeIcon size="xs" color="green" icon="plus" />;
    return (
        <div className="options">
            <Box {...animationProps} className="box" m={40} p={20}>
                <Label color={green}>
                    <Paragraph fontFamilySans size={sm} bold color={white} uppercase>
                        30 DAY FREE TRIAL
                    </Paragraph>
                </Label>
                <LibBox height="100%">
                    <SecondaryHeadline mt={70} mb={20} size={lg}>
                        FOR TEAMS THAT DON`T NEED SUPPORT
                    </SecondaryHeadline>
                    <Item mt={2}>
                        {plus} Unlimited experiments, facts, insight and recommendations
                    </Item>
                    <Item mt={2}>{plus} Unlimited free view only accounts</Item>
                    <Item mt={2}>{plus} Training and resource materials</Item>
                    <Item mt={2}>{plus} Access to our community Slack channel</Item>
                    <Item mt={2} mb={200}>
                        {plus} Support via email
                    </Item>
                    <LibBox width="calc(100% - 40px)" position="absolute" bottom={20}>
                        <Item mt={2}>
                            <b>£18</b> per contributor per month (paid yearly)
                        </Item>
                        <Item mt={2}>
                            <b>£22</b> per contributor per month (paid monthly)
                        </Item>
                        <Item>
                            <b>£0</b> unlimited free viewers
                        </Item>
                        <LibBox mt={4}>
                            <a
                                href={getAppHost('/signup')}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button color={white} bg={darkblue}>
                                    Get started (30 days free)
                                </Button>
                            </a>
                        </LibBox>
                    </LibBox>
                </LibBox>
            </Box>
            <Box {...animationProps} className="box" m={40} p={20}>
                <Label color={pink}>
                    <Paragraph fontFamilySans size={sm} bold color={white} uppercase>
                        WE`LL GET YOU SET UP
                    </Paragraph>
                </Label>
                <LibBox height="100%">
                    <SecondaryHeadline mt={70} mb={20} size={lg}>
                        FULLY MANAGED SERVICE
                    </SecondaryHeadline>
                    <Item mt={2}>
                        Let us support you every step of the way. From onboarding new colleagues,
                        regular feedback sessions, workshops, to daily support and advice.
                    </Item>
                    <Item mt={2}>
                        Think of us as your (very cheap) knowledge management consultants, here to
                        ensure you get the full value of your repository.
                    </Item>
                    <Item mt={2}>
                        <b>Includes everything self-serve offers plus:</b>
                    </Item>
                    <Item mt={2}>{plus} Dedicated research manager</Item>
                    <Item mt={2}>{plus} Personalized training</Item>
                    <Item mt={2}>{plus} Workshops (for example refining your taxonomy)</Item>
                    <Item mt={2}>{plus} Regular feedback sessions</Item>
                    <Item mt={2}>{plus} Prioritised feature requests</Item>
                    <Item mt={2}>{plus} Custom intergrations</Item>
                    <Item mt={2}>{plus} Single sign-on</Item>
                    <Item mt={2}>{plus} Fixed annual contracts available</Item>
                    <LibBox mt={5}>
                        <Item mt={2}>
                            Let us customise a package for you - but to give you an idea:
                        </Item>
                        <Item mt={2}>
                            Up to <b>£50</b> per contributor per month*
                        </Item>
                        <Item mt={2}>
                            <b>£0</b> unlimited free viewers
                        </Item>
                        <Item mt={2}>
                            *Some contributors may need less support, in which case we won`t charge
                            you as much!
                        </Item>
                        <LibBox mt="auto">
                            <a
                                href="https://gleanly.youcanbook.me/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <LibBox mt={4}>
                                    <Button color={white} bg={darkblue}>
                                        book a demo
                                    </Button>
                                </LibBox>
                            </a>
                        </LibBox>
                    </LibBox>
                </LibBox>
            </Box>
        </div>
    );
};

const Video = () => {
    const { darkblue, white, lightgrey } = useTheme().colors;
    const { lg } = useTheme().fontSize;
    return (
        <div className="video">
            <Box {...animationProps} className="box" p={20}>
                <SecondaryHeadline mt={40} mb={20} size={lg}>
                    View our demo video
                </SecondaryHeadline>
                <div className="iframe-container">
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/TcGPIyMl_x4"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                <Flex mt="auto" justifyContent="space-evenly" flexWrap="wrap">
                    <a
                        href="https://gleanly.youcanbook.me/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button color={darkblue} bg={lightgrey}>
                            Book an in-person demo
                        </Button>
                    </a>
                    <a href={getAppHost('/signup')} target="_blank" rel="noopener noreferrer">
                        <Button color={white} bg={darkblue}>
                            Try it yourself (30 days free)
                        </Button>
                    </a>
                </Flex>
            </Box>
        </div>
    );
};

const App = () => {
    return (
        <Layout>
            <Content>
                <MenuBar />
                <Options />
                <Video />
            </Content>
        </Layout>
    );
};

export default App;
